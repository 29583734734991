const MuiBottomNavigationTheme = {
  styleOverrides: {
    root: {
      background: "#F9F9F9",
      borderRadius: "12px",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      "& :first-child": {
        borderRadius: `12px 0 0 12px`,
      },
      "& :last-child": {
        borderRadius: `0 12px 12px 0`,
      },
      "& .MuiButtonBase-root": {
        background: "#F9F9F9",
        textDecoration: "none",
        textAlign: "center",
        width: "100%",
        maxWidth: "unset",
      },
      "& .MuiBottomNavigationAction-label": {
        fontWeight: "bold",
        color: "#A7A9AB",
      },
      "& .Mui-selected": {
        backgroundColor: "#BFBB93",
        color: "#FFFFFF",
        borderRadius: "10px",
      },
      "& .Mui-selected .MuiBottomNavigationAction-label": {
        fontSize: "0.8rem",
        fontWeight: "bold",
      },
      "& .MuiButtonBase-root:active": {
        opacity: "0.7",
        boxShadow: "none",
        backgroundColor: "#C8C8C8",
        borderRadius: "10px",
      },
      "& .MuiButtonBase-root:hover": {
        opacity: "0.7",
        boxShadow: "none",
        backgroundColor: "#C8C8C8",
        borderRadius: "10px",
      },
      "& .Mui-selected:hover": {
        opacity: "1",
        backgroundColor: "#BFBB93",
        color: "#FFFFFF",
        borderRadius: "10px",
      },
      "& .MuiButtonBase-root:hover .MuiBottomNavigationAction-label": {
        color: "#FFFFFF",
      },
    },
  },
};

export default MuiBottomNavigationTheme;
