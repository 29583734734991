const MuiRadioTheme = {
  styleOverrides: {
    root: {
      color: "#BFBB93",
      "& .MuiTypography-root": {
        fontSize: "0.875rem",
        lineHeight: "147%",
      },
      "& .MuiSvgIcon-root": {
        color: "#BFBB93",
      },
      "&.Mui-checked": {
        color: "#BFBB93",
      },
    },
  },
};

export default MuiRadioTheme;
