import { createTheme } from "@mui/material/styles";

import MuiButtonTheme from "./MuiButtonTheme";
import MuiTextFieldTheme from "./MuiTextFieldTheme";
import MuiRadioTheme from "./MuiRadioTheme";
import MuiCheckboxTheme from "./MuiCheckboxTheme";
import MuiAlertTheme from "./MuiAlertTheme";
import MuiBottomNavigationTheme from "./MuiBottomNavigationTheme";
import MuiOutlinedInput from "./MuiOutlinedInput";
import MuiSwitch from "./MuiSwitch";

import RobotoBlack from "../public/assets/fonts/Roboto-Black.ttf";
import RobotoBold from "../public/assets/fonts/Roboto-Bold.ttf";
import RobotoMedium from "../public/assets/fonts/Roboto-Medium.ttf";

import HelveticaLight from "../public/assets/fonts/Roboto-Light.ttf";
import HelveticaRoman from "../public/assets/fonts/Roboto-Regular.ttf";

const ConnectAnywhereTheme = createTheme({
  typography: {
    h1: {
      fontSize: "4.5rem",
      "@media (max-width:1200px)": {
        fontSize: "3rem",
      },
    },
    h2: {
      fontSize: "3.75rem",
      "@media (max-width:1200px)": {
        fontSize: "2.2rem",
      },
    },
    h3: {
      fontSize: "3rem",
      "@media (max-width:1200px)": {
        fontSize: "2rem",
      },
    },
    h4: {
      fontSize: "2.25rem",
      "@media (max-width:1200px)": {
        fontSize: "1.85rem",
      },
    },
    h5: {
      fontSize: "1.5rem",
      "@media (max-width:1200px)": {
        fontSize: "1.25rem",
      },
    },
    h6: {
      fontSize: "1.25rem",
      "@media (max-width:1200px)": {
        fontSize: "1rem",
      },
    },
    subtitle1: {
      fontSize: "1rem",
    },
    subtitle2: {
      fontSize: "0.875rem",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
    fontFamily: ['"Roboto"', '"Helvetica"', "sans-serif", "-apple-system"].join(
      ","
    ),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Roboto';
          src: local('Roboto'), local('Roboto'), url(${RobotoMedium}) format('truetype');
        }

        @font-face {
          font-family: 'Roboto';
          font-weight: 600;
          src: local('Roboto'), local('Roboto'), url(${RobotoBold}) format('truetype');
        }

        @font-face {
          font-family: 'Roboto';
          font-weight: 900;
          src: local('Roboto'), local('Roboto'), url(${RobotoBlack}) format('truetype');
        }

        @font-face {
          font-family: "Helvetica";
          font-weight: light;
          src: local("Helvetica"), local("Helvetica"),
            url(${HelveticaLight}) format('truetype');
        }
        
        @font-face {
          font-family: "Helvetica";
          src: local("Helvetica"), local("Helvetica"),
            url(${HelveticaRoman}) format('truetype');
        }
      `,
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.MuiTypography-paragraph": {
            fontFamily: "Helvetica",
          },
        },
      },
      defaultProps: {
        variantMapping: {
          h1: "h2",
          h2: "h2",
          h3: "h2",
          h4: "h2",
          h5: "h2",
          h6: "h2",
          subtitle1: "h2",
          subtitle2: "h2",
          body1: "span",
          body2: "span",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          "&.MuiAppBar-root": {
            backgroundColor: "#2C2C2C",
            color: "#FFFFFF!important",
          },
        },
      },
    },
    MuiButton: MuiButtonTheme,
    // MuiTextField: MuiTextFieldTheme,
    MuiRadio: MuiRadioTheme,
    MuiCheckbox: MuiCheckboxTheme,
    // MuiAlert: MuiAlertTheme,
    MuiBottomNavigation: MuiBottomNavigationTheme,
    MuiOutlinedInput: MuiOutlinedInput,
    MuiSwitch: MuiSwitch,
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#A7A9AB",
          "&.Mui-focused": {
            color: "#A7A9AB",
          },
        },
      },
    },
  },
});

export default ConnectAnywhereTheme;
