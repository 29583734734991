import { Provider } from 'next-auth/client';
import dynamic from 'next/dynamic';
import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from 'react';
import { getCookie } from 'cookies-next';
import { configuration } from '../lib/configuration';
import { AppContextProvider } from '../store/app-context';
import theme from "../theme";

const Layout = dynamic(() => import('../components/Layout/Layout'), {
  ssr: false
});

import Head from 'next/head';
import '../styles/globals.scss';

function MyApp({ Component, pageProps }) {
  const [pressedKeys, setPressedKeys] = useState([]);
  const ALLOWED_KEYS = ['Shift'];

  // Initialize the singleton Configuration object with public env vars from the pageProps.process.
  if (pageProps.process) {
    configuration.setProcessIfNotAlreadySet(pageProps.process);
  }

  const authorized = getCookie('authorized');

  pageProps.pressedKeys = pressedKeys;

  useEffect(() => {
    const onKeyDown = ({ key }) => {
      if (ALLOWED_KEYS.includes(key) && !pressedKeys.includes(key)) {
        setPressedKeys(previousPressedKeys => [...previousPressedKeys, key]);
      }
    }

    const onKeyUp = ({ key }) => {
      if (ALLOWED_KEYS.includes(key)) {
        setPressedKeys(previousPressedKeys => previousPressedKeys.filter(k => k !== key));
      }
    }

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id='root'>
      <Provider session={pageProps.session}
      options={{
        clientMaxAge: 60,
        keepAlive: 5 *60
      }}>
        <AppContextProvider>
          <ThemeProvider theme={theme}>
            <Layout pressedKeys={pressedKeys} authorized={authorized} process={pageProps.process}>
              <Head>
                <title>ConnectAnywhere</title>
              </Head>
              <Component {...pageProps} />
            </Layout>
          </ThemeProvider>
        </AppContextProvider>
      </Provider>
    </div>
  )
}

export default MyApp;