import { createContext, useState } from 'react'

const AppContext = createContext({
  username: '',
  setUsername: function(string){},
  email: '',
  setEmail: function(string){},
  contactId: '',
  setContactId: function(string) {},
  displaySnack: false,
  setDisplaySnack: function(boolean){},
  snackMessage: '',
  setSnackMessage: function(string){},
  snackError: false,
  setSnackError: function(boolean){},
  token: '',
  setToken: function(string){},
  authenticated: false,
  setAuthenticated: function(boolean){},
  buildId: '',
  page: '',
  setPage: function(string) {},
  developerMode: false,
  setDeveloperMode: function() {},
  showModal: function (modalData) {},
  hideModal: function () {},
  setRequestLogout: function(boolean) {},
  requestLogout: false,
  setLoggingOut: function(boolean) {},
  loggingOut: false
})

export function AppContextProvider(props) {
  const [username, setUsername] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [contactId, setContactId] = useState(undefined);
  const [displaySnack, setDisplaySnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackError, setSnackError] = useState(false);
  const [token, setToken] = useState(undefined);
  const [authenticated, setAuthenticated] = useState(false);
  const [buildId, setBuildId] = useState(undefined);
  const [activeModal, setActiveModal] = useState()
  const [developerMode, setDeveloperMode] = useState(false);
  const [page, setPage] = useState('undefined');
  const [requestLogout, setRequestLogout] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);

  function showModalHandler(modalData) {
    setActiveModal(modalData)
  }

  function hideModalHandler() {
    setActiveModal(null)
  }

  const context = {
    username: username,
    setUsername: setUsername,
    email: email,
    setEmail: setEmail,
    contactId: contactId,
    setContactId: setContactId,
    displaySnack: displaySnack,
    setDisplaySnack: setDisplaySnack,
    snackMessage: snackMessage,
    setSnackMessage: setSnackMessage,
    snackError: snackError,
    setSnackError: setSnackError,
    token: token,
    setToken: setToken,
    authenticated: authenticated,
    setAuthenticated: setAuthenticated,
    buildId: buildId,
    setBuildId: setBuildId,
    page: page,
    setPage: setPage,
    developerMode: developerMode,
    setDeveloperMode: setDeveloperMode,
    modal: activeModal,
    showModal: showModalHandler,
    hideModal: hideModalHandler,
    requestLogout: requestLogout,
    setRequestLogout: setRequestLogout,
    loggingOut: loggingOut,
    setLoggingOut: setLoggingOut
  }

  return (
    <AppContext.Provider value={context}>
        {props.children}
    </AppContext.Provider>
  )
}

export default AppContext
