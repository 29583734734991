const MuiCheckboxTheme = {
  styleOverrides: {
    root: {
      color: "#D1D2D4",
      "&.Mui-checked": {
        color: "#BFBB93",
      },
      "&.Mui-disabled": {
        opacity: "0.5",
      },
    },
  },
};

export default MuiCheckboxTheme;
