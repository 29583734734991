const MuiOutlinedInput = {
  styleOverrides: {
    root: {
      "& fieldset": {
        borderColor: "#D1D2D4 !important",
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#D1D2D4 !important",
      },
      "&:focus fieldset": {
        borderColor: "#D1D2D4 !important",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#bfbb93!important",
        borderWidth: "2px",
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D32F2F !important",
        borderWidth: "1px",
      },
      "&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D32F2F !important",
        borderWidth: "1px",
      },
      "&:hover.Mui-error.Mui-focused fieldset.MuiOutlinedInput-notchedOutline":
        {
          borderColor: "#D32F2F !important",
        },
      "& .Mui-disabled": {
        backgroundColor: "#E6E7E8",
        borderColor: "#D1D2D4",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#D1D2D4",
      },
      "&.Mui-disabled:hover fieldset": {
        borderColor: "#D1D2D4",
      },
    },
  },
};

export default MuiOutlinedInput;
