export interface IZipitAddDeviceParams {
    catalogItemId?: string
    claimCode?: string
    completedRedirectUrl?: string
    redirectOnCompletion?: boolean
    failureRedirectUrl?: string
}
class Configuration {
    process: any

    // Call this method from each page so as to handle case where user bypasses the normal page sequence
    // when they navigate directly to a given page from the browser.
    setProcessIfNotAlreadySet = (proc: any) => {
        if (proc && proc.env && (!this.process || !this.process.env)) {
            this.process = proc;
        }
    }

    getProcess = () => {
        return this.process;
    }

    getBaseIoTCommunityURL = () => {
        const iotCommunityBaseURL = this.process.env.NEXT_PUBLIC_IOT_COMMUNITY_URL;
        return iotCommunityBaseURL;
    }

    getLogoutIoTCommunityURL = () => {
        const iotCommunityLogoutUrl = `${this.getBaseIoTCommunityURL()}/services/auth/idp/oidc/logout`;
        return iotCommunityLogoutUrl;
    }

    getAppDomain = () => {
        const domain = this.process.env.NEXT_PUBLIC_DOMAIN;
        return domain;
    }

    getZipitBillingAddDeviceURL = (props: IZipitAddDeviceParams) => {
        const { catalogItemId, claimCode, completedRedirectUrl, redirectOnCompletion = false, failureRedirectUrl } = props;
        const zipitBillingBaseUrl = this.process.env.NEXT_PUBLIC_ZIPIT_BILLING_URL;
        const params: any = {
            catalogItemId: catalogItemId,
            redirectOnCompletion: redirectOnCompletion
        };
        if (claimCode) {
            params['value'] = claimCode;
        }
        if (completedRedirectUrl) {
            params['completedRedirectUrl'] = completedRedirectUrl;
        }
        if (failureRedirectUrl) {
            params['failureRedirectUrl'] = failureRedirectUrl;
        }
        const stringifiedParams = JSON.stringify(params);
        const encodedParams = encodeURIComponent(stringifiedParams);
        const completeZipitBillingUrl = `${zipitBillingBaseUrl}?pf=add-device&pfParams=${encodedParams}`;
        return completeZipitBillingUrl;
    }

    // Method invoked by server-side code:
    static getIntrospectionURL = (process: any) => {
        const loginUrl = process.env.NEXT_PUBLIC_SALESFORCE_URL;
        const introspectionUrl = loginUrl + '/services/oauth2/introspect';
        return introspectionUrl;
    }
}

// Referenced by server-side code.
export default Configuration;

// Referenced by client-side code.
export const configuration = new Configuration();
