const MuiSwitch = {
    styleOverrides: {
        root: {
            width: 55,
            height: 30,
            padding: 0,
            '& .MuiSwitch-switchBase': {
                padding: 0,
                margin: "2.5px 15.5px 2.5px 5px",
                transitionDuration: '300ms',
                '&.Mui-checked': {
                  transform: 'translateX(22px)',
                  color: '#fff',
                  '& + .MuiSwitch-track': {
                    backgroundColor: '#BFBB93',
                    opacity: 1,
                    border: 0,
                  },
                  '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                  },
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                  color: '#33cf4d',
                  border: '6px solid #fff',
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                  color: 'red'
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.7,
                },
            },
            '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 24,
                height: 24,
            },
            '& .MuiSwitch-track': {
                borderRadius: 30 / 2,
                backgroundColor: '#E9E9EA',
                opacity: 1,
            },
        }
    }
}

export default MuiSwitch;
