const MuiButtonTheme = {
  styleOverrides: {
    textPrimary: {
      borderRadius: "8px",
      color: "#F04E23",
      ":hover": {
        borderColor: "#F04E23",
        backgroundColor: `rgba(220, 53, 19, 0.08)`,
      },
    },
    textSecondary: {
      color: "#2C2C2C",
      ":hover": {
        borderColor: "#2C2C2C",
        backgroundColor: `rgba(53, 53, 53, 0.08)`,
      },
    },
    textError: {
      color: "#fff",
      ":hover": {
        borderColor: "#2C2C2C",
        backgroundColor: `rgba(255, 255, 255, 0.08)`,
      },
    },
    outlinedPrimary: {
      color: "#F04E23",
      borderColor: "#F04E23",
      borderWidth: "2px",
      borderRadius: "8px",
      ":hover": {
        border: "2px solid #F04E23 !important",
        backgroundColor: `rgba(220, 53, 19, 0.08)`,
      },
    },
    outlinedSecondary: {
      color: "#2C2C2C",
      ":hover": {
        border: "2px solid #2C2C2C !important",
        backgroundColor: `rgba(255, 255, 255, 0.08)`,
      },
      backgroundColor: "#fff",
      border: "2px solid #2C2C2C",
      width: "100%",
      borderRadius: "6px",
    },
    containedPrimary: {
      backgroundColor: "#F04E23",
      borderRadius: "8px",
      ":hover": {
        backgroundColor: "#F04E23",
      },
    },
    containedSecondary: {
      backgroundColor: "#2c2c2c",
      color: "#f9f9f9 !important",
      boxShadow:
        "0px 6px 21px 2px rgba(163, 163, 163, 0.57), inset 0px 4px 14px rgba(238, 238, 238, 0.28)",
      ":hover": {
        backgroundColor: "#2c2c2c",
        boxShadow:
          "0px 6px 21px 2px rgba(163, 163, 163, 0.57),inset 0px 4px 14px rgba(238, 238, 238, 0.28)",
      },
      "[disabled]": {
        backgroundColor: "#A7A9AB",
        boxShadow: "inset 0px 4px 14px rgba(238, 238, 238, 0.28)",
      },
    },
    containedError: {
      backgroundColor: "#FFFFFF",
      color: "#2C2C2C !important",
      width: "100%",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
    },
    root: {
      fontSize: "0.875rem",
      borderRadius: "4px",
      minHeight: "34px",
      minWidth: "109px",
      fontWeight: "bold",
      lineHeight: "112%",
      padding: `9px 0px`,
      paddingBottom: "15px",
      paddingTop: "20px",
      maxWidth: "338px",
      width: "100%",
    },
  },
};

export default MuiButtonTheme;
